@use "./global.scss";

.partnermaindiv {
  text-align: center;
  margin: 8rem 0 15rem;
}

.partnermaindiv img {
  width: 20%;
  object-fit: contain;
  margin-top: 2rem;
}

.heading {
  font-size: global.$extra-large-text;
  font-weight: 200;
  margin-top: 20px;
  margin-bottom: 30px;
}

.description {
  font-size: global.$small-text;
  flex-direction: column-reverse;
}

@media all and (min-width: 768px) and (max-width: 1279px) {
  .heading {
    font-size: global.$extra-large-text;
    font-weight: 200;
  }
  .description {
    font-size: global.$small-text;
  }
  .partnermaindiv img {
    width: 35%;
    object-fit: contain;
  }
}

@media all and (min-width: 100px) and (max-width: 767px) {
  .heading {
    font-size: global.$large-text;
    font-weight: 200;
  }
  .description {
    font-size: global.$extra-small-text;
  }
  .partnermaindiv img {
    width: 40%;
    object-fit: contain;
  }
}
