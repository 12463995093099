@use "./global.scss";

.maindiv {
  text-align: center;
  position: relative;
  margin: 18rem 0 18rem;
}
.aboutheading {
  font-size: global.$extra-large-text;
  font-weight: 200;
  margin-bottom: 30px;
}
.description {
  font-size: global.$small-text;
  line-height: 1.4;
}
.bgDiv {
  height: 85vh;
  background-color: white;
  width: 0.5rem;
  position: absolute;
  top: -16vw;
  left: 50%;
  opacity: 0.08;
}

@media all and (min-width: 768px) and (max-width: 1279px) {
  .aboutheading {
    font-size: global.$extra-large-text;
    font-weight: 200;
    margin-bottom: 30px;
  }
  .description {
    font-size: global.$small-text;
  }
  .bgDiv {
    height: 50vh;
    background-color: white;
    width: 0.5rem;
    position: absolute;
    top: -45%;
    left: 50%;
    opacity: 0.08;
  }
  .maindiv {
    margin: 10rem 0 10rem;
  }
}

@media all and (min-width: 100px) and (max-width: 767px) {
  .aboutheading {
    font-size: global.$large-text;
    font-weight: 200;
  }
  .description {
    font-size: global.$extra-small-text;
  }
  .bgDiv {
    height: 50vh;
    background-color: white;
    width: 0.5rem;
    position: absolute;
    top: -20%;
    left: 50%;
    opacity: 0.08;
  }
  .maindiv {
    margin: 3rem 0 5rem;
  }
}
