@use "./global.scss";

.mainFooterContainer {
  margin: 2rem auto 10rem;
  padding: 1.5rem 0 1.5rem 0;
  box-shadow: rgba(100, 100, 111, 0.15) 0px 0px 50px 0px;
}
.footerMain {
  width: 75%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.footerImgDiv {
  width: 20%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.footerImgDiv img {
  width: 75%;
}
.location {
  width: 19%;
}

.location a {
  color: global.$text-color;
  &:hover {
    opacity: 0.8;
  }
}

.hidden {
  visibility: hidden;
}
.location div h3 {
  font-size: global.$medium-text;
  font-weight: 200;
  letter-spacing: 0.1em;
  margin-bottom: 20px;
}
.location div p {
  font-size: global.$extra-small-text;
  letter-spacing: 0.1em;
  font-weight: 200;
  margin-bottom: 20px;
}
.location h4 {
  font-size: global.$extra-small-text;
  margin-bottom: 10px;
}
.location p {
  font-size: global.$extra-extra-small-text;
  font-weight: 400;
  line-height: 1.5em;
  margin-bottom: 10px;
  line-height: 1.7;
}

@media all and (min-width: 768px) and (max-width: 1279px) {
  .mainFooterContainer {
    margin: 5rem auto 5rem;
  }
  .footerImgDiv {
    justify-content: center;
    align-items: center;
  }
  .location {
    width: 38%;
  }
}

@media all and (min-width: 100px) and (max-width: 767px) {
  .mainFooterContainer {
    margin: 5rem auto 3rem;
  }
  .footerMain {
    width: 90%;
    margin: auto;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .footerImgDiv {
    width: 50%;
    margin: 0 auto;
  }
  .hidden {
    display: none;
  }
  .location {
    width: 100%;
    margin: 0 auto 1.5rem;
  }
  .location div h3 {
    font-size: global.$medium-text;
  }
  .location div p {
    font-size: global.$custom-email-text;

    text-align: center;
    width: 100%;
    margin-bottom: 1rem;
  }
  .location h4 {
    text-align: center;
    font-weight: 100;
  }
  .location p {
    width: 85%;
    margin: auto;
  }
}
