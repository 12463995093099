@use "./global.scss";

.navbarMain {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 2rem 0;
  position: fixed;
  z-index: 1;
  background-color: #142d4c;
  display: none;
}

.coName {
  width: 30%;
  text-align: left;
  font-size: global.$large-text;
  padding-left: 1rem;
}

.sections {
  width: 50%;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: global.$extra-small-text;
}

.sections.showMenu {
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #142d4c;
  text-align: center;
  padding: 1rem 0;
  font-size: global.$custom-email-text;
}

.sections button {
  background-color: global.$consultation-button;
  color: global.$text-color;
  text-decoration: none;
  border: none;
  border-radius: 1.5rem;
  padding: 1rem 1.5rem;
  cursor: pointer;
  font-size: global.$extra-small-text;
  font-weight: bold;
}

.hamburger {
  display: none;
}

@media all and (max-width: 1023px) {
  .coName {
    width: 50%;
  }

  .sections {
    display: none;
  }

  .sections div,
  button {
    margin: 0.25rem 0;
  }

  .hamburger {
    display: block;
    cursor: pointer;
    padding: 0.5rem;
  }

  .line {
    width: 1.5rem;
    height: 0.2rem;
    background-color: white;
    margin: 5px;
  }

  .sections.showMenu {
    display: flex;
  }
}

@media all and (max-width: 506px) {
  .sections div,
  button {
    margin: 0.25rem 0;
  }

  .coName {
    width: 60%;
    font-size: global.$medium-text;
  }
}
