$background-color: #142d4c;
$font-family: "Michroma", sans-serif;
$text-color: #fff;
$default-margin: 0;

$consultation-button: #91e2a8;

$extra-extra-large-text: 5.75rem;
$extra-large-text: 3.85rem;
$large-text: 1.875rem;
$medium-text: 1.5rem;
$small-text: 1.25rem;
$extra-small-text: 1rem;
$extra-extra-small-text: 0.625rem;
$custom-contact-text: 2.5rem;
$custom-email-text: 0.85rem;

body {
  background-color: $background-color;
  font-family: $font-family;
  color: $text-color;
  margin: $default-margin;
}
