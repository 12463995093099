* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: baseline;
  -webkit-tap-highlight-color: transparent;
  font-variant-ligatures: none !important;
  font-feature-settings: "liga";
  -webkit-user-drag: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

input[type="text"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type="text"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.fix::after {
  clear: both;
  content: "";
  display: table;
}

input,
textarea,
button {
  outline: none;
  cursor: inherit;
}

*:focus {
  outline: none;
}

*::-moz-focus-inner {
  border: 0;
}

textarea {
  min-height: 100px;
}

input:required {
  box-shadow: none;
}

input:invalid {
  box-shadow: none;
}

.unselect,
.unselect *,
br,
hr,
img,
.blockui {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

img {
  max-width: 100%;
}

a,
a:link,
a:active,
a:visited,
a:hover,
a:focus,
a:focus-within {
  outline: none;
  border: none;
  text-decoration: none;
}

ul,
li {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
}

.none {
  display: none !important;
}

body {
  line-height: 1;
  min-height: 100vh;
  width: 100%;
  word-spacing: 0;
  letter-spacing: 0;
  z-index: 1;
  font-kerning: normal;
  font-feature-settings: "kern", "liga", "clig", "calt";
  position: relative;
  overflow-x: hidden;
}

.mainContainer {
  width: 100%;
  padding: 0 calc(2vw + 10px);
  margin: auto;
}
