@use "./global.scss";

.headingMain {
  margin: 0 auto 6rem;
  position: relative;
}
.headingInner {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: 0 auto;
  padding-top: 22rem;
}
.headingMain::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/img/stars.png");
}
.headingImg img {
  margin-top: 2.5rem;
  width: 70%;
}
.headingContent {
  width: 40%;
}
.headingContent h1 {
  font-size: 115.2px;
  font-weight: 200;
  text-align: left;
  margin-bottom: 50px;
  line-height: 1.4;
}
.headingContent h2 {
  font-size: global.$large-text;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 40%;
  padding-left: 0.2rem;
}
.barDiv {
  height: 60vh;
  width: 0.5rem;
  background-color: white;
}

@media all and (min-width: 1280px) {
  .headingMain {
    margin: 0 auto 6rem;
    position: relative;
  }
  .headingInner {
    display: flex;
    justify-content: space-between;
    width: 95%;
    margin: 0 auto;
    padding-top: 24rem;
  }
  .headingMain::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../assets/img/stars.png");
  }
  .headingImg img {
    margin-top: 2.5rem;
    width: 70%;
  }
  .headingContent {
    width: 65%;
    height: 50vh;
    max-width: 245rem;
  }
  .headingContent h1 {
    font-size: 115.2px;
    font-weight: 200;
    text-align: left;
  }
  .headingContent h2 {
    font-size: global.$large-text;
    font-weight: 400;
    line-height: 1.4;
    margin-bottom: 40%;
    padding-left: 0.2rem;
  }
  .barDiv {
    margin-top: 12rem;
    margin-right: 12%;
    height: 60vh;
    width: 0.5rem;
    background-color: white;
  }
}

@media all and (min-width: 768px) and (max-width: 1279px) {
  .barDiv {
    display: none;
  }
  .headingMain {
    width: 100%;
    height: 80vh;
  }
  .headingInner {
    padding-top: 30%;
  }
}

@media all and (min-width: 100px) and (max-width: 767px) {
  .headingInner {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
  }
  .headingImg img {
    width: 70%;
    margin: 0 0 0.5rem;
  }
  .headingContent {
    width: 100%;
  }
  .headingContent h1 {
    font-size: global.$extra-large-text;
    font-weight: 200;
    text-align: center;
    margin-top: 50px;
  }
  .headingContent h2 {
    font-size: global.$medium-text;
    font-weight: 400;
    line-height: 1.5em;
    margin-bottom: 20%;
  }
  .barDiv {
    display: none;
  }
}

@keyframes twinkling {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@for $i from 0 through 199 {
  .twinkling-star-#{$i} {
    position: absolute;
    width: 2px;
    height: 2px;
    background-color: white;
    top: percentage(random());
    left: percentage(random());
    animation: twinkling 1s infinite;
    opacity: 0;
  }

  .headingMain .twinkling-star-#{$i} {
    animation-delay: $i * 0.2s;
  }
}
