@use "./global.scss";

.reverseflex_1 {
  width: 90%;
  max-width: 900px;
  margin: calc(30px + 4vh) auto;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}
.normalflex_1 {
  width: 90%;
  max-width: 900px;
  margin: calc(30px + 4vh) auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}
.reverseflex_2 {
  width: 90%;
  max-width: 900px;
  margin: calc(30px + 4vh) auto;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}
.normalflex_2 {
  width: 90%;
  max-width: 900px;
  margin: calc(30px + 4vh) auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}
.imagecontainer {
  width: 60%;
}
.details {
  width: 90%;
  max-width: 900px;
}
.details h2 {
  font-size: global.$large-text;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 20px;
}
.details p {
  font-size: global.$small-text;
  line-height: 1.7;
}

@media all and (min-width: 100px) and (max-width: 767px) {
  .reverseflex_1 {
    flex-direction: column;
  }
  .normalflex_1 {
    flex-direction: column;
  }
  .reverseflex_2 {
    flex-direction: column;
  }
  .normalflex_2 {
    flex-direction: column;
  }
  .imagecontainer {
    margin: auto;
  }
  .details {
    margin: auto;
    text-align: center;
  }
  .details h2 {
    font-size: global.$medium-text;
    font-weight: 400;
    line-height: 1.4;
  }
  .details p {
    font-size: global.$extra-small-text;
    line-height: 1.6em;
  }
}
