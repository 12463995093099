@use "./global.scss";

.socialMain p {
  text-align: center;
  font-size: global.$extra-extra-small-text;
  font-weight: 100;
  letter-spacing: 0.5px;
}
.fbicon {
  border-radius: 50%;
  height: 34px;
  width: 34px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    opacity: 0.8;
  }
}

.iconDiv {
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-bottom: 2rem;
}
.socialMain {
  margin-top: 4rem;
}

@media all and (min-width: 100px) and (max-width: 767px) {
  .socialMain {
    margin-top: 1.5rem;
  }
}
