@use "./global.scss";

.contactouter {
  position: relative;
}
.contactinner {
  width: 50%;
  margin: auto;
  text-align: center;
  background-image: url("../assets/img/sky.json");
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.contactinner h1 {
  font-size: global.$extra-extra-large-text;
  font-weight: 200;
  margin-bottom: 40px;
}
.contactinner p {
  font-size: global.$small-text;
  margin-bottom: 40px;
}
.contactinner button {
  background-color: global.$consultation-button;
  color: global.$text-color;
  text-decoration: none;
  border: none;
  border-radius: 8px;
  padding: 20px;
  cursor: pointer;
  font-size: global.$extra-small-text;
  font-weight: 700;
  transition: all 0.25s;
  &:hover {
    background-color: #6bbe83;
  }
}
.backgroundstars {
  width: 90%;
  margin: auto;
}

@media all and (min-width: 768px) and (max-width: 1279px) {
  .contactinner {
    width: 80%;
  }
}

@media all and (min-width: 100px) and (max-width: 767px) {
  .contactinner {
    width: 100%;
    top: 50%;
  }
  .contactinner h1 {
    font-size: global.$custom-contact-text;
  }
  .backgroundstars {
    margin-top: 1.5rem;
    width: 100%;
  }
}
